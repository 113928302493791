import React, { useState, Component, useEffect } from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import CardBlog from "../components/CardBlog";
import ContactsBlock from "../components/ContactsBlock";

import DecorationBlob18 from "../images/decorations/deco-blob-18.svg";
import DecorationBlob3 from "../images/decorations/deco-blob-3.svg";

import ContactsBlockLandingPage from "../components/ContactsBlockLandingPage";
import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import LogoAgentAdvance from "../images/logos/brand/agent-advance.svg";
import LogoThinkpricing from "../images/logos/brand/think-pricing-colored.svg";
import LogoThinkpricingGrey from "../images/logos/brand/grey-thinkpricing.svg";
import LogoKinteract from "../images/logos/brand/grey-kinteract.svg";
import LogoQolo from "../images/logos/brand/qolo-colored.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import ThinkpricingReview from "../images/logos/thinkpricing-review.svg";
import AgentadvanceReview from "../images/logos/agentadvance-review.svg";
import Testimonials from "../components/Testimonials";

import IconCheck from "../images/icons/interface/check.svg";
import IconPointer from "../images/icons/pages/home/pointer.svg";
import IconGlobe from "../images/icons/pages/home/globe.svg";
import IconBulb from "../images/icons/pages/home/bulb.svg";
import IconCross from "../images/icons/interface/cross.svg";
import IconRoad from "../images/icons/theme/tools/road-cone.svg";
import IconShovel from "../images/icons/theme/tools/shovel.svg";

import Divider1 from "../images/dividers/divider-3.svg";
// import Divider2 from "../images/dividers/divider-2.svg";
// import Divider2 from '../images/dividers/divider-2.custom.svg';

import DecorationDots6 from "../images/decorations/deco-dots-6.svg";
import DecorationBlob6 from "../images/decorations/deco-blob-6.svg";
import DecorationLine1 from "../images/decorations/deco-lines-1.svg";
import DecorationLine6 from "../images/decorations/deco-lines-6.svg";
import { GatsbyImage } from "gatsby-plugin-image";
import QuoteIconGrey from "..//images/icons/theme/text/quote-1-grey.svg";
// import { useMixpanelTracker } from "../hooks/useMixpanelTracker";
import { IndexJsonLd } from "../components/jsonld/IndexJsonLd";
import useInViewPort from "../hooks/useInViewPort";
import SubscriptionAiReview from "../components/SubscriptionAiReview"
import EbookPricing from "../components/EbookPricing";
// import DecorationBlob19 from "../images/decorations/deco-blob-19.svg";

const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
    <picture>
        <source
            type="image/webp"
            srcSet={`${src}?fm=webp&w=200 200w,
${src}?fm=webp&w=400 400w,
${src}?fm=webp&w=800 800w,
${src}?fm=webp&w=1200 1200w,
${src}?fm=webp&w=1436 1436w`}
            sizes="(max-width: 800px) 100vw, 800px"
        />
        <source
            srcSet={`${src}?w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
            sizes="(max-width: 800px) 100vw, 800px"
        />
        <img
            sizes="(max-width: 800px) 100vw, 800px"
            srcSet={`${src}?fm=webp&w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
            src={`${src}?w=1200`}
            alt={alt || ""}
            className={className || ""}
            style={style || {}}
            loading={lazy ? "lazy" : "eager"}
        />
    </picture>
);

const AiEbook = ({ data, location: { pathname } }) => {
    const [isInViewRef, isInView] = useInViewPort();
    const FeaturedArticlesData = data.featuredArticles.edges;

    return (
        <Layout isMainPage footerBgClass="bg-primary-alt">
            <div className="index-page pillar-page">
                <IndexJsonLd />
                <SectionFirstScreen data={data} />
                <SectionGoodbyeExpenses data={data} />

                <SectionWhatsInside data={data} />
               {/*  <TestimonialBlock data={data} /> */}
               {/*  <SectionBonuses data={data} /> */}
                {/* <SectionWhyUXConsulting data={data} /> */}
                {/* <SectionWhatsInside data={data} /> */}

                {/* <SectionUseCases data={data} /> */}

                {/* <SectionHowItWorks data={data} /> */}
                <SectionOurImpact data={data} />
                
                
                {/* <SectionBlog FeaturedArticlesData={FeaturedArticlesData} /> */}
                {/* <SectionTrustedCompanies /> */}
                <EbookPricing backgroundClass="bg-white" title={false} data={data} />

                <SectionFaq data={data} />
                <EbookPricing fullMode={false} data={data}/>

                <div ref={isInViewRef}> </div>

                {/* <SectionIndustries data={data} /> */}
                {/* <SectionTopUXAgency data={data} /> */}
                {/* <SectionOurBook data={data} /> */}
            </div>

            {/* <ContactsBlockLandingPage isInViewProp={isInView} /> */}
        </Layout>
    );
};

const SectionFirstScreen = ({ data }) => {
    const ImageHero = data.AiUxEbook.childImageSharp.gatsbyImageData;

    return (
        <section className="o-hidden py-5 py-md-7 first-section">
            <div className="container">
                <div className="row align-items-center min-vh-40">
                    <div className="col-md-7 col-lg-7 text-left mb-4 mb-lg-0">
                        <span className="pb-2 pre-heading">Advance Your Entire UX Process</span>

                        <h1 className="display-4 ">
                            Unlock a no-stress design process with 
                            <span className="text-primary"> AI for UX </span>ebook
                        </h1>

                        <h2 className="h2">
                           The only ebook you'll ever need to master AI for UX design.
                        </h2>

                        <div className="my-4 lead">
                            <div className="mb-1 text-white badge badge-success">Benefits:</div>

                            <ul className="list-unstyled">
                                <li className="d-flex py-1 align-items-center">
                                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                                        <IconCheck className="injected-svg icon bg-white" />
                                    </div>
                                    <span className="lead">
                                        <strong></strong>Learn once, apply everywhere
                                    </span>
                                    
                                </li>
                                <li className="d-flex py-1 align-items-center">
                                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                                        <IconCheck className="injected-svg icon bg-white" />
                                    </div>
                                    <span className="lead">
                                        <strong></strong>Endless design innovation & ideas
                                    </span>

                                </li>
                                <li className="d-flex py-1 align-items-center">
                                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                                        <IconCheck className="injected-svg icon bg-white" />
                                    </div>
                                    <span className="lead">
                                        <strong></strong>Earn more, work less
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="pb-2">
                            <span className="new-notification bg-primary-3 text-white px-2 py-1 rounded mb-2">Limited Offer: 30% Off</span>
                        </div>
                        <a
                            href="https://buy.stripe.com/6oE29G5IE4RXexq28n"
                            target="_blank"
                            id="freeconsultation"
                            className="btn btn-lg btn-primary btn-payment lead-cta"
                        >
                            Maximize Your UX<span>$ 49</span>
                        </a>
                        {/* <p className="small mt-2 font-bold">7-day money-back guarantee</p> */}

                        {/* <a
              className="mt-4 mt-md-0 ml-0 ml-md-2 btn btn-lg btn-outlined text-primary button lead-cta"
              id="freeconsultation"
              target="_blank"
              href="/start-from-scratch"
            >
              Start from scratch
            </a> */}
                    </div>
                    <div className="d-none d-md-block col-md-5 text-center" style={{ position: "relative" }}>
                        <div className="">
                            <GatsbyImage alt={"ImageApp"} style={{ maxWidth: 1000 }} image={ImageHero} loading="eager" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionGoodbyeExpenses = ({ data }) => {

    return (
        <section className="py-4 pt-xl-7 layer-4">
            <div className="decoration-wrapper d-none d-lg-block">
                <div className="decoration scale-1 top" style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}>
                    <DecorationDots6 className="bg-yellow" />
                </div>
            </div>
            <div className="container bg-primary-alt rounded py-6">
                <div className="row align-items-center">
                    <div className="col-md-8 offset-md-2 text-left">
                        <h2 className="display-4 mb-4">Listen Designers!</h2>

                        <p className="lead pt-2">
                            Are you <strong>struggling with how to use AI</strong> in your design process and those <span className="text-danger"><strong>free prompts</strong></span> aren't doing the promised magic?
                        </p>

                        <p className="lead pt-2">
                            But imagine that there was a simple guide...
                        </p>

                        <p className="lead pt-2">
                        ...full of real examples and smart tips, to make AI work for you irrespective of your level of design. 
                        </p>

                        <p className="lead pt-2">
                            <strong>This eBook is just that!</strong>
                        </p>

                        <p className="lead pt-2">
                            It’s easy to understand and packed with secrets and helps to boost your designs. 
                        </p>

                        <p className="lead pt-2">
                            <strong>Say goodbye to confusion and hello to amazing AI-powered designs!</strong>
                        </p>
                    </div>

                    <div className="col-md-6">
                       

  
                    </div>
                </div>
            </div>
    
        </section>
    );
};


const SectionWhatsInside = ({ data }) => {
    const ImageIndustries = data.AiUxExtract.childImageSharp.gatsbyImageData;

    return (
        <section className="py-4 pt-xl-7 layer-4">
            <div className="decoration-wrapper d-none d-lg-block">
                <div className="decoration scale-1 top" style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}>
                    <DecorationDots6 className="bg-yellow" />
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-4 order-1 order-md-0">
                        <GatsbyImage image={ImageIndustries} alt="Industries" loading={"lazy"} />
                    </div>

                    <div className="col-md-6 order-0 order-md-1">
                        <h2 className="h1 mb-4">What's inside?</h2>

                        <p className="lead">
                            You'll walk away with...
                        </p>

                        <ul className="list-unstyled mb-2">
                        <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                                    <IconCheck className="injected-svg icon bg-white" />
                                </div>
                                <span className="lead mt-n1">
                                <strong>A to Z secrets of using AI in UX Design:</strong>
                                    <br />
                                     Master AI in UX with our comprehensive, step-by-step guide. Perfect for both AI beginners and advanced users.
                                </span>
                            </li>

                        <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                                    <IconCheck className="injected-svg icon bg-white" />
                                </div>
                                <span className="lead mt-n1">
                                    <strong>Practical real-world examples: </strong>
                                    <br />
                                    Ground your learning in reality with our curated collection of real-world case studies and examples.
                                </span>
                            </li>
                            <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                                    <IconCheck className="injected-svg icon bg-white" />
                                </div>
                                <span className="lead mt-n1">
                                    <strong>Unknown secrets of prompting: </strong>
                                    <br />
                                    Don't just learn the art of effective AI prompting with examples. Also, become a prompt machine yourself.
                                </span>
                            </li>
                            <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs bg-primary mr-2">
                                    <IconCheck className="injected-svg icon bg-white" />
                                </div>
                                <span className="lead mt-n1">
                                    <strong>Access to our private community:</strong>
                                    <br />
                                     Extend your learning experience with a free AMA (Ask Me Anything) through the Slack community. Interact directly with me, ask questions, and discuss your thoughts on AI in UX design.
                                </span>
                            </li>
                            
                        </ul>
                      
              <Link
              to="https://buy.stripe.com/6oE29G5IE4RXexq28n"
              target="_blank"
              id="freeconsultation"
              className="btn btn-lg btn-primary lead-cta"
              >
                Master AI for UX Design Now
              </Link>

                    </div>
                </div>
            </div>
            {/* <div className="divider bg-primary-alt">
          <Divider1 className="" />
        </div> */}
        </section>
    );
};


const SectionUseCases = ({ data }) => {
    const ImageUseCaseMobile = data.ImageUseCaseMobile.childImageSharp.gatsbyImageData;
    const ImageUseCaseSaaS = data.ImageUseCaseSaaS.childImageSharp.gatsbyImageData;
    const ImageUseCaseWebsite = data.ImageUseCaseWebsite.childImageSharp.gatsbyImageData;

    const [activeToggle, setActiveToggle] = useState(1);
    const [isHovering, setIsHovering] = useState(false);
    const [borderHeight, setBorderHeight] = useState('0%');
    const [transitionStyle, setTransitionStyle] = useState('none');



    const handleToggle = (id) => {
        setActiveToggle((currentActive) => (currentActive === id ? currentActive : id));
    };

    useEffect(() => {
        let timer;
        let borderAnimationTimer;

        // Reset border height to 100% when activeToggle changes
        if (activeToggle && !isHovering) {
            setBorderHeight('100%');
            setTransitionStyle('height 4s linear');
            borderAnimationTimer = setTimeout(() => setBorderHeight('0%'), 4000);
        } else {
            setTransitionStyle('none');
        }

        // Set the timer only if there is no hover
        if (!isHovering) {
            timer = setTimeout(() => {
                setActiveToggle((currentActive) => {
                    // Determine the next ID (or cycle through them)
                    const nextId = currentActive === 3 ? 1 : currentActive + 1;
                    return nextId;
                });
            }, 4000); // Change the active state every 5 seconds
        }

        // Clear the timeout if the component is unmounted or if hover starts
        return () => {
            clearTimeout(timer);
            clearTimeout(borderAnimationTimer);
        };

    }, [isHovering, activeToggle]);

    // Call this when mouse enters any toggleable area
    const handleMouseEnter = () => {
        setIsHovering(true);
    };

    // Call this when mouse leaves any toggleable area
    const handleMouseLeave = () => {
        setIsHovering(false);
    };

    return (
        <section className="py-4 pt-xl-7 layer-4">
            <div className="decoration-wrapper d-none d-lg-block">
                <div className="decoration scale-1 top" style={{ right: "20%", transform: "matrix(-1, 0, 0, 1, 0, 0)" }}>
                    <DecorationDots6 className="bg-yellow" />
                </div>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h2 className="h1 mb-4">Extract from the ebook</h2>
                        <style>
                            {`.active-usecase::before { height: ${borderHeight} !important; transition: ${transitionStyle} !important;}`}
                        </style>
                        <ul className="list-unstyled mb-2">
                            <li className={`d-flex usecase py-3 ${activeToggle === 1 ? "active-usecase" : ""}`} onClick={() => handleToggle(1)} style={{ cursor: "pointer" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {/* <div className={`icon-round icon-round-full icon-round-xs mr-2 ${activeToggle === 1 ? 'bg-primary' : ''}`}>
                  <IconCheck className="injected-svg icon bg-white" />
                </div> */}
                                <span className="lead mt-n1">
                                    <strong>...</strong>
                                    <br />
                                    Enhance SaaS Dashboards: Elevate the usability and functionality of your SaaS platform with our insights, ensuring a seamless experience for your users.
                                </span>

                                

                            </li>

                            <li className={`d-flex usecase py-3 ${activeToggle === 2 ? "active-usecase" : ""}`} onClick={() => handleToggle(2)} style={{ cursor: "pointer" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {/* <div className={`icon-round icon-round-full icon-round-xs mr-2 ${activeToggle === 2 ? 'bg-primary' : ''}`}>
                  <IconCheck className="injected-svg icon bg-white" />
                </div> */}
                                <span className="lead mt-n1">
                                    <strong>Conversion Rate Optimization for Websites & Landing Pages</strong>
                                    <br />
                                    Boost Conversion Rates: Apply our UX review to your web presence to uncover friction points, streamline navigation, and optimize for conversions.
                                </span>
                               
                            </li>

                            <li className={`d-flex usecase py-3 ${activeToggle === 3 ? "active-usecase" : ""}`} onClick={() => handleToggle(3)} style={{ cursor: "pointer" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {/* <div className={`icon-round icon-round-full icon-round-xs mr-2 ${activeToggle === 3 ? 'bg-primary' : ''}`}>
                  <IconCheck className="injected-svg icon bg-white" />
                </div> */}
                                <span className="lead mt-n1">
                                    <strong>Mobile App Refinement</strong>
                                    <br />
                                    Perfect User Interfaces: Use our report to polish your mobile app’s UX, making every interaction intuitive and engaging for on-the-go users.
                                </span>
                               
                            </li>
                        </ul>
                    </div>

                    <div className={`col-md-6 mb-4 ${activeToggle === 1 ? "d-block" : "d-none"}`}>
                        <GatsbyImage image={ImageUseCaseSaaS} alt="Industries" className="col-md-11" loading={"lazy"} />
                    </div>

                    <div className={`col-md-6 mb-4 ${activeToggle === 3 ? "d-block" : "d-none"}`}>
                        <GatsbyImage image={ImageUseCaseMobile} alt="Industries" className="col-md-11" loading={"lazy"} />
                    </div>

                    <div className={`col-md-6 mb-4 ${activeToggle === 2 ? "d-block" : "d-none"}`}>
                        <GatsbyImage image={ImageUseCaseWebsite} alt="Industries" className="col-md-11" loading={"lazy"} />
                    </div>
                </div>
            </div>
            {/* <div className="divider bg-primary-alt">
          <Divider1 className="" />
        </div> */}
        </section>
    );
};

const SectionOurImpact = ({ data }) => {
    const ImageOurProcess = data.ImageOurProcess.childImageSharp.gatsbyImageData;
    const ImageAdamLi = data.ImageAdamLi.childImageSharp.gatsbyImageData;
    const ImageAdamPhoto = data.ImageAdamPhoto.childImageSharp.gatsbyImageData;
    

    return (
        <section className="o-hidden py-4 pt-xl-7">
            <div className="decoration-wrapper d-none d-lg-block">
                <div className="decoration scale-1" style={{ top: 160, right: "12%" }}>
                    <DecorationLine6 className="bg-primary" />
                </div>
            </div>

            <div className="container bg-primary-alt py-6 rounded">
                <div className="row justify-content-center text-left text-md-center mb-2">
                    <div className="col-xl-8 col-lg-9">
                        <h2 className="display-4 mx-xl-5">About the Author</h2>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 offset-md-2 text-left">
                        <p className="h1 mt-2">
                            👋 Hi, I'm Adam Fard, the creative force behind this eBook. 
                        </p>

                        <p className="lead pt-2">
                           I started my career 12 years ago.                         
                        </p>

                        <p className="lead pt-2">
                            In 2016 after holding a leadership role in a traditional 9-5 job, I transitioned to freelancing in search of more freedom. This shift led to success, and ultimately, I founded my own remote agency.                        
                        </p>
                        
                        <div style={{maxWidth:'460px', margin:'auto'}}>
                            <GatsbyImage className="rounded" image={ImageAdamPhoto} alt="Our Process" style={{ flexGrow: 1 }} loading={"lazy"} />
                        </div>

                        <p className="lead pt-4">
                            I have had the opportunity to lead several innovative design projects over the years including collaboration with global brands like <strong>Samsung, T-Mobile, Hubspot, and many more.</strong>
                        </p>


                        <p className="lead pt-2">
                            "AI for UX" marks a new chapter in my career. Following my growing interest in AI technology, I have delved deeper into AI, focusing on leveraging AI solutions and <strong>developing AI models for specific purposes</strong>.            
                        </p>
                        
                        <GatsbyImage image={ImageAdamLi} alt="Our Process" style={{ flexGrow: 1 }} loading={"lazy"} />

                        <p className="lead pt-4">
                            During this process, I founded UX Pilot: the AI-assistant for UX.
                        </p>

                        <p className="lead pt-2">
                            This eBook is my way of sharing everything I've learned along this path. It is <strong>filled with practical strategies and techniques.</strong>
                        </p>

                        <p className="lead pt-2 pb-4">
                            Let's uncover how AI can elevate your UX designs.
                        </p>

                 
{/* 
                        <div className="pb-2">
                            <span className="new-notification bg-primary-3 text-white px-2 py-1 rounded mb-2">Early Bird: 40% Off</span>
                        </div>

                        <p className="text-center text-md-left mx-auto">
                            <a
                                //to="https://buy.stripe.com/6oEcOk9YU1FL74Y000" //199 EUR
                                href="https://buy.stripe.com/8wMeWsc7298d892dR1"
                                // to="/contact-us"
                                target="_blank"
                                id="freeconsultation"
                                className="btn btn-lg btn-primary btn-payment lead-cta"
                            >
                                Start a UX Review<span>$ 99</span>
                            </a> */}
                            {/* <p className="small mt-2 text-center text-md-left">7-day money-back guarantee</p> */}
                            {/* <p className="small mt-2">Secure payment powered by Stripe</p> */}
                        {/* </p> */}
                    </div>

                    <div className="col-md-6 d-flex align-items-center order-0 order-md-0 mb-4 mb-md-0">
                    </div>
                </div>

                {/*
          <div className="col-md-6 order-1 order-md-0">
            <div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">$114 ROI for every 1$</h5>
                  <p>
                  We’re sure we measure up because we measure. 
                  We’ve helped numerous businesses get the most out of UX.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">57% increase in sales</h5>
                  <p>
                  We’re sure you’ve put the heart and soul into your product. 
                  Let us make sure it’s a pleasure to use.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">300% reduced costs on dev & support</h5>
                  <p>
                  You don’t need many support representatives, if the design does the talking. 
                  Neither do you need countless iterations if the process is set-up right.
                  </p>
                </div>
              </div>
              <div className="process noline">
                <div className="process-circle bg-primary">
                  <IconCheck className="injected-svg icon bg-white" />
                </div>
                <div className="ml-3">
                  <h5 className="mb-1">78% Conversion Increase</h5>
                  <p>
                  Let your product do the selling. We’re here to guide you through every step of the way.
                  </p>
                </div>
              </div>

            </div>
          </div>

        
        */}
            </div>
        </section>
    );
};

const SectionHowItWorks = ({ data }) => {
    const ImageOurProcess = data.ImageOurProcess.childImageSharp.gatsbyImageData;

    return (
        <section className="o-hidden py-4 pt-xl-7">
            <div className="decoration-wrapper d-none d-lg-block">
                <div className="decoration scale-1" style={{ top: 160, right: "12%" }}>
                    <DecorationLine6 className="bg-primary" />
                </div>
            </div>

            <div className="container">
                <div className="row justify-content-center text-left text-md-center mb-2">
                    <div className="col-xl-8 col-lg-9">
                        <h2 className="display-4 mx-xl-5">Here's how it works</h2>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-md-4 order-1 order-md-1">
                        <ul className="list-unstyled mb-2">
                            <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs text-white bg-primary mr-2">1</div>
                                <span className="lead  mt-n1">
                                    <strong>Book the Review</strong>
                                    <br />
                                    Tell us your business goals to tailor the review.
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4 order-1 order-md-1">
                        <ul className="list-unstyled mb-2">
                            <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs text-white bg-primary mr-2">2</div>
                                <span className="lead mt-n1">
                                    <strong>Fill the intake form (5 min)</strong>
                                    <br />
                                    Share the most important screens.
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div className="col-md-4 order-1 order-md-1">
                        <ul className="list-unstyled mb-2">
                            <li className="d-flex py-3">
                                <div className="icon-round icon-round-full icon-round-xs text-white bg-primary mr-2">3</div>
                                <span className="lead  mt-n1">
                                    <strong>Get your actionable review</strong>
                                    <br />
                                      Within 24hrs receive all the action points and improvements.
                                </span>
                            </li>
                        </ul>
                    </div>
            
                </div>

                    

                    <div className="row justify-content-center text-center mb-2 mt-4">
                        <div className="col-xl-8 col-lg-9">
                            {/* <div className="pb-2">
                                <span className="new-notification bg-primary-3 text-white px-2 py-1 rounded mb-2">Black Friday: 40% Off</span>
                            </div> */}
                            <p className="text-align-center">
                                <a
                                    //to="https://buy.stripe.com/6oEcOk9YU1FL74Y000" //199 EUR
                                    href="https://buy.stripe.com/6oE29G5IE4RXexq28n"
                                    // to="/contact-us"
                                    target="_blank"
                                    id="freeconsultation"
                                    className="btn btn-lg btn-primary btn-payment lead-cta"
                                >
                                    Book your UX Review<span>$ 99</span>
                                </a>
                                <p className="small mt-2 text-center">7-day money-back guarantee</p>
                                {/* <p className="small mt-2">Secure payment powered by Stripe</p> */}
                            </p>
                        </div>
                    </div>

                  {/*   <div className="col-md-6 d-flex align-items-center order-0 order-md-0 mb-4 mb-md-0">
                        <GatsbyImage image={ImageOurProcess} alt="Our Process" style={{ flexGrow: 1 }} loading={"lazy"} />
                    </div>
 */}
            </div>
        </section>
    );
};

const SectionBonuses = ({ data }) => {
    const ImageBonus1 = data.ImageBonus1.childImageSharp.gatsbyImageData;
    const ImageBonus2 = data.ImageBonus2.childImageSharp.gatsbyImageData;
    const ImageBonus3 = data.ImageBonus3.childImageSharp.gatsbyImageData;


    return (
        <section className="py-4">

            <div className="container bonus-container p-4 rounded">
                <div className="row justify-content-center text-left text-md-center mb-2">
                    <div className="col-xl-8 col-lg-9">
                        <h2 className="display-4 mx-xl-5 mb-2">Free Added Bonus 🎁</h2>
                        <p className="lead">
                            To supercharge your success, every purchase comes with complimentary resources:
                        </p>
                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="text-center col-md-4 order-1 order-md-1 mb-3">
                        <div className="px-6">
                            <GatsbyImage image={ImageBonus2} alt="Ultimate UX Checklist" className="mb-2" loading={"lazy"} />
                        </div>
                        <span className="lead  mt-n1">
                            Ultimate UX Checklist
                            <br />
                            <strong className="text-primary"><span className="text-strikethrough text-primary-3">$45</span> included</strong>
                        </span>
                    </div>

                    <div className="text-center col-md-4 order-1 order-md-1 mb-3">
                        <div className="px-6">
                            <GatsbyImage image={ImageBonus3} alt="Ultimate UX Checklist" className="mb-2" loading={"lazy"} />
                        </div>
                        <span className="lead  mt-n1">
                            Guide to Competitive Analysis
                            <br />
                            <strong className="text-primary"><span className="text-strikethrough text-primary-3">$29</span> included</strong>
                        </span>
                    </div>

                    <div className="text-center col-md-4 order-1 order-md-1 mb-3">
                        <div className="px-6">
                            <GatsbyImage image={ImageBonus1} alt="Ultimate UX Checklist" className="mb-2" loading={"lazy"} />
                        </div>
                        <span className="lead  mt-n1">
                            138 ChatGPT Prompts: UX Research
                            <br />
                            <strong className="text-primary"><span className="text-strikethrough text-primary-3">$39</span> included</strong>
                        </span>
                    </div>
            
                </div>

                <div className="row justify-content-center text-center mt-4">
                    <div className="col-xl-8 col-lg-9">
                        {/* <h2 className="h1 mx-xl-5 mb-0 pb-0">Actionable UX Review</h2> */}
                        <h2 className="h1 mx-xl-5 mb-3 pb-0">$113 Value Included for FREE</h2>
                    </div>
                </div>

                <div className="row justify-content-center text-center mb-2 mt-0">
                        <div className="col-xl-8 col-lg-9">
                            <p className="text-align-center">
                                <a
                                    //to="https://buy.stripe.com/6oEcOk9YU1FL74Y000" //199 EUR
                                    href="https://buy.stripe.com/6oE29G5IE4RXexq28n"
                                    // to="/contact-us"
                                    target="_blank"
                                    id="freeconsultation"
                                    className="btn btn-lg btn-primary btn-payment lead-cta"
                                >
                                    Book your UX Review<span>$ 99</span>
                                </a>
                                <p className="small mt-2 text-center">7-day money-back guarantee</p>
                                {/* <p className="small mt-2">Secure payment powered by Stripe</p> */}
                            </p>
                        </div>
                    </div>

            </div>
        </section>
    );
};


const TestimonialBlock = ({ data }) => {
    const thinkpricingAvatar = data.thinkpricingAvatar.childImageSharp.gatsbyImageData;
    const qoloAvatar = data.qoloAvatar.childImageSharp.gatsbyImageData;
    const agentAvatar = data.agentAvatar.childImageSharp.gatsbyImageData;

    return (
        <Testimonials
            className="pt-0 pb-4 pb-md-6 pt-md-0 mt-n6 layer-4 testimonial-compact"
            /* title="What Our Customers Say" */
            testimonials={[
                {
                    content: `The final UX review thoroughly addressed all our requirements and offered valuable insights. 
                      It demonstrated **remarkable efficiency and precision**, impressively interpreting our design needs and integrating feedback effectively.`,
                    imageFixed: thinkpricingAvatar,
                    logo: <ThinkpricingReview className="align-items-left" style={{ width: "178px" }} />,
                },
                {
                    content: `The MVP underwent a **comprehensive UX review** that garnered positive feedback from the internal team. Adam Fard UX Studio provided **insightful and high-quality UX analyses** that aligned well with the client's needs.`,
                    imageFixed: qoloAvatar,
                    logo: <QoloReview className="align-items-left" style={{ width: "148px" }} />,
                },
                {
                    content: `Adam Fard UX Studio delivered a UX review that combined the **personalized attention** of a small agency with the **quality and professionalism** of a larger firm.`,
                    imageFixed: agentAvatar,
                    logo: <AgentadvanceReview className="align-items-left" style={{ width: "143px" }} />,
                },
            ]}
        />
    );
};

const SectionFaq = ({ data }) => {
    const projectsThinkpricingImg = data.projectsThinkpricingImg.childImageSharp.gatsbyImageData;
    const projectsKinteractImg = data.projectsKinteractImg.childImageSharp.gatsbyImageData;
    const projectsQoloImg = data.projectsQoloImg.childImageSharp.gatsbyImageData;

    const toggleAccordion = (e) => {
        const panel = e.target;
        const panelAccordionContent = panel.querySelector(`.accordion-content`);
        const panelIcon = panel.querySelector(".icon");

        if (panelAccordionContent.className.includes("collapse")) {
            panelAccordionContent.classList.remove("collapse");
            panelIcon.classList.add("icon-rotate");
        } else {
            panelAccordionContent.classList.add("collapse");
            panelIcon.classList.remove("icon-rotate");
        }
    };

    return (
        <section className="o-hidden bg-primary-alt has-divider">
            <div className="divider bg-white">
                <Divider1 className="bg-primary-alt" />
            </div>
            <div className="container py-3">
                <div className="row mt-md-4 justify-content-center">
                    <div className="col-12 d-flex flex-column text-center">
                        <article id="article-content" className="article article-blog" style={{ zIndex: 9993 }}>
                            <h2 className="display-4 text-center">FAQ</h2>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1"> Who is this eBook intended for? </strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p> This eBook is designed for anyone interested in integrating AI into their UX design process, regardless of their current level of expertise. Whether you're a beginner or an advanced user, the step-by-step guide, practical examples, and smart tips make it accessible and valuable for all.</p>
                                    </div>
                                </div>
                            </div>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1"> Do I need to be a UX designer to benefit from this?</strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p>No, you don't need to be a UX designer to use and benefit from this eBook. Many of the principles and strategies outlined in 'AI for UX' are applicable across various professions and tasks. The insights and techniques provided can enhance a wide range of creative and analytical endeavors, making it a valuable resource for anyone interested in leveraging AI in their field.</p>
                                    </div>
                                </div>
                            </div>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1"> What's unique compared to other design resources? </strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p>'AI for UX' stands out by offering a blend of comprehensive AI education tailored for UX design. It includes real-world case studies, effective AI prompting techniques, and secrets of the trade that you won't find in standard design resources. This eBook is a direct pipeline to expert knowledge and practical applications.</p>
                                    </div>
                                </div>
                            </div>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1">Is there ongoing support or a community? </strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p>
                                            Absolutely! With your purchase, you gain access to our private Slack community. This platform allows you to engage in Ask Me Anything (AMA) sessions, interact with fellow designers, and discuss AI in UX design directly with the author and other experts.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1"> Can these principles be applied to different AI platforms? </strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p>Yes, the principles and techniques covered in 'AI for UX' are versatile and can be applied across various AI tools and platforms. The eBook includes use cases for popular AI tools like ChatGPT, GPT-4, and Midjourney, ensuring that the knowledge you gain is broad and adaptable</p>
                                    </div>
                                </div>
                            </div>

                            <div onClick={toggleAccordion} style={{ cursor: "pointer" }} id="panel-1" className="card card-sm card-body card--no-hover text-left">
                                <div style={{ pointerEvents: "none" }} className="accordion-panel-title" aria-controls="panel-1">
                                    <p aria-controls="panel-1">
                                        <strong aria-controls="panel-1"> Do you offer refunds? </strong>
                                    </p>
                                    <DatoAsset className="icon" src="https://www.datocms-assets.com/16499/1583766454-plus.svg" aria-controls="panel-1" />
                                </div>
                                <div style={{ pointerEvents: "none" }} className="accordion-content collapse">
                                    <div className="pt-3">
                                        <p>Due to the nature of the product being a digital eBook, we do not offer refunds.</p>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </section>
    );
};

const SectionTrustedCompanies = () => (
    <section className="py-4 py-md-3">
        <div className="container">
            <div className="row text-center mb-0">
                <div className="col-12 col-md-8 mx-auto mb-4">
                    {/* <div className="mb-1 badge badge-primary-2"></div> */}
                    {/* <h2 className="h2 mb-2">Contact Us </h2> */}
                    {/* <p clss="lead text-align-center">
            As a special Winter deal, <strong>we charge EUR 199 (~ USD 225) for 1 hour of consultation.</strong> The price will go to EUR 300 (~ USD 340) after February 28th, so don't hesitate 🙂
          </p> */}

                    {/* <p className="text-align-center">
            <Link
            // to="https://buy.stripe.com/6oEcOk9YU1FL74Y000"
            to="/contact-us"
            // target="_blank"
            id="freeconsultation"
            className="btn btn-lg btn-primary lead-cta"
            >
              Get in Touch
            </Link>
          </p> */}
                </div>
            </div>

            <div className="row text-center mb-3">
                <div className="col">
                    <small className="">Trusted by companies like</small>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
                        <li>
                            <a target="_blank" href="/projects/ux-design-samsung-app-testing-platform">
                                <LogoSamsung className="" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="/contact-us">
                                <LogoTmobile className="" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="/projects/edtech-saas-design">
                                <LogoKinteract className="" />
                            </a>
                        </li>
                        <li>
                            <a target="_blank" href="/projects/saas-b2b-platform">
                                <LogoThinkpricingGrey className="" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
);


export default AiEbook;

export const query = graphql`
    query AiUxEbook {
        ImageGrow: file(relativePath: { eq: "photos/expertise.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageIndustries: file(relativePath: { eq: "photos/ux-review-example.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageUseCaseMobile: file(relativePath: { eq: "photos/usecase-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageUseCaseSaaS: file(relativePath: { eq: "photos/usecase-saas.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageUseCaseWebsite: file(relativePath: { eq: "photos/usecase-website.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageOurProcess: file(relativePath: { eq: "photos/impacts-ux.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageAdamLi: file(relativePath: { eq: "photos/adam-linkedin.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageAdamPhoto: file(relativePath: { eq: "photos/1690805471072.jpeg" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        AiUxEbook: file(relativePath: { eq: "photos/ai-ux-ebook.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        AiUxExtract: file(relativePath: { eq: "photos/ai-ux-extract.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageHero: file(relativePath: { eq: "photos/ux-review-report.png" }) {
            childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 1800)
            }
        }
        LogoClutch: file(relativePath: { eq: "logos/brand/Top_B2B_Western_Europe.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        LogoGoodfirms: file(relativePath: { eq: "logos/brand/mobile-app-design-companies.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        LogoDAN: file(relativePath: { eq: "logos/brand/featured-ux-studio.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        agentAvatar: file(relativePath: { eq: "photos/agent-avatar.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 400, height: 400, layout: FIXED)
            }
        }
        thinkpricingAvatar: file(relativePath: { eq: "photos/think-avatar.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 400, height: 400, layout: FIXED)
            }
        }
        qoloAvatar: file(relativePath: { eq: "photos/qolo-avatar.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 400, height: 400, layout: FIXED)
            }
        }
        projectsThinkpricingImg: file(relativePath: { eq: "photos/home-projects-thinkpricing.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        projectsKinteractImg: file(relativePath: { eq: "photos/home-projects-kinteract.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        projectsQoloImg: file(relativePath: { eq: "photos/home-projects-qolo.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        fintechImg: file(relativePath: { eq: "photos/Fintech.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        healthcareImg: file(relativePath: { eq: "photos/Healthcare.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        saasImg: file(relativePath: { eq: "photos/SaaS.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        communicationImg: file(relativePath: { eq: "photos/IT-Communication.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        educationImg: file(relativePath: { eq: "photos/Education.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        itImg: file(relativePath: { eq: "photos/home-it.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageBonus1: file(relativePath: { eq: "photos/bonus-1.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageBonus2: file(relativePath: { eq: "photos/bonus-2.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        ImageBonus3: file(relativePath: { eq: "photos/bonus-3.png" }) {
            childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
        datoCmsPage(slug: { eq: "ai-ux-design-review" }) {
            seo {
                title
                description
                twitterCard
                image {
                    url
                }
            }
        }
        featuredArticles: allDatoCmsArticle(limit: 3, sort: { fields: date, order: DESC }) {
            edges {
                node {
                    title
                    cardDescription
                    cardImage {
                        gatsbyImageData(width: 544, layout: CONSTRAINED)
                    }
                    category {
                        name
                    }
                    id
                    slug
                }
            }
        }
    }
`;
