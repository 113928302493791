import React from "react";
import IconMail from "../images/icons/theme/communication/send.svg";
import { Helmet } from "react-helmet";
import IconCheck from "../images/icons/interface/check.svg";
import ImgCustom from "../images/photos/custom-ux-plan.jpg";
import { useLocation } from "../hooks/useLocation";
import { GatsbyImage } from "gatsby-plugin-image";

const EbookPricing = ({backgroundClass = "bg-primary-alt", fullMode=true, title=true, data}) => {
    const { location, isLoading } = useLocation();

    const ImageBonus1 = data.ImageBonus1.childImageSharp.gatsbyImageData;
    const ImageBonus2 = data.ImageBonus2.childImageSharp.gatsbyImageData;
    const ImageBonus3 = data.ImageBonus3.childImageSharp.gatsbyImageData;

    return (
        <section id="subscriptionBlock" className={`section-contacts section-contacts-block pt-0 pt-md-3 pb-6 position-relative ${backgroundClass} `}>
            <div className="container">
                <div className="row">
                    {title &&
                        <div className="col-12 col-lg-10 text-center mb-2 mb-md-3 mx-auto">
                            <h2 className="display-4 mb-2">Don't wait until it's too late</h2>
                            <p className="col-xl-7 col-lg-8 col-md-10 mx-auto lead">
                                Improve your AI knowledge and gain a competitive edge in UX
                            </p>
                        </div>
                    }
                    <div className="col-12 col-md-6 offset-md-3 mb-4">
                        <div className="border-primary-3 border border-2 rounded pt-4 pb-4 px-3 bg-white">
                            <h4>AI for UX Design eBook</h4>

                                    <div className="lead align-items-start">
                                        
                                        <span className="h2 opacity-30 text-strikethrough mr-1 font-weight-normal">$70</span>
                                        
                                        <span className="ml-2 h2 mr-2">$49</span>

                                        <span className="badge badge-primary-3">Limited Offer: 30% Off</span>
                                    </div>

                        {/*     <p className="mt-1">
                                7-day money-back guarantee
                            </p> */}
                            <div className="d-flex flex-column text-center pt-1 lead">
                                <a
                                    
                                    href="https://buy.stripe.com/6oE29G5IE4RXexq28n"
                                    // to="/contact-us"
                                    target="_blank"
                                    id="freeconsultation"
                                    className="btn btn-lg btn-primary btn-payment lead-cta p-2"
                                >
                                    Maximize Your AI Knowledge Now
                                </a>
                                
                            </div>


                            {fullMode &&
                            <>
                                <p className="h4 primary-3 pt-4 mb-4 font-weight-bold">What you get:</p>

                                <ul className="list-unstyled mb-0 pb-0">
                                    <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <span>
                                            <p><strong className="lead font-weight-bold">An actionable 58-page ebook</strong> 
                                            <br/>
                                            that holds your hand and teaches you all that you need to excel in UX design using AI</p>
                                        </span>
                                    </li>
                                    <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <span>
                                            <p><strong className="lead font-weight-bold">ChatGPT, GPT-4, Midjourney and more</strong></p>
                                        </span>
                                    </li>
                                    <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <p>
                                            <strong className="lead font-weight-bold">16 use cases for AI in research and design</strong>
                                        </p>
                                    </li>
                                    <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <span>
                                            <p><strong className="lead font-weight-bold">Access to our private community for Q&A</strong></p>
                                        </span>
                                    </li>
                                    <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <span>
                                            <strong className="lead font-weight-bold">Versatile AI Knowledge</strong>
                                            <p>You can apply principles you'll learn to all AI products</p>
                                        </span>
                                    </li>
                                   {/*  <li className="d-flex py-2">
                                        <div className="icon-xl mr-2">
                                            <IconCheck className="injected-svg icon primary-3" />
                                        </div>
                                        <p className="mb-1">
                                            <strong className="lead font-weight-bold">Bonus: $48 Worth of Resources for 🎁 FREE</strong>
                                        </p>
                                    </li>
                                    <div className="row px-4 ">
                                        <div className="col-4">
                                            <GatsbyImage image={ImageBonus1} alt="Guide to Competitive Analysis" className="" loading={"lazy"} />
                                        </div>
                                        <div className="col-4">
                                            <GatsbyImage image={ImageBonus2} alt="Guide to Competitive Analysis" className="" loading={"lazy"} />
                                        </div>
                                        <div className="col-4">
                                            <GatsbyImage image={ImageBonus3} alt="Guide to Competitive Analysis" className="" loading={"lazy"} />
                                        </div>
                                    </div> */}
                                </ul>
                            </>}
                        </div>
                    </div>
                   
                   {/*  <div className="col-12 mx-auto justify-content-center text-center">
                        <div className="d-flex flex-row mx-auto rounded pt-2 pt-md-4 pb-4 px-3 justify-content-center align-items-center">
                            <div className="flex-column lead w-auto">
                                <h4 className="h1">Specific needs?</h4>
                                <p>Tailor your needs to a specific custom design project plan.</p>
                                <a href="/contact-us" target="_blank" className="lead text-primary mt-3 hover-arrow">
                                    Contact us
                                </a>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default EbookPricing;
